import {graphql, StaticQuery} from 'gatsby'
import React from 'react'
import {ThemeProvider} from 'styled-components'
import theme from '../styles/theme'
import '../styles/layout.css'

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
    }
  }
`

function LayoutContainer (props) {
  return (
    <ThemeProvider theme={theme} >
      <StaticQuery
        query={query}
        render={data => {
          if (!data.site) {
            throw new Error(
              'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
            )
          }
          return (
            <>
              {props.children}
            </>
          )
        }}
      />
    </ThemeProvider>
  )
}

export default LayoutContainer
